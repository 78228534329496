import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1E2730] text-center p-6 mt-12">
      <div className="flex justify-center space-x-6 mb-4">
        <a
          href="https://x.com/restec_ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter className="text-white hover:text-[#ED4251]" size={30} />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61560886237075"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="text-white hover:text-[#ED4251]" size={30} />
        </a>
        <a
          href="https://www.instagram.com/restec.ia/?next=%2F"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="text-white hover:text-[#ED4251]" size={30} />
        </a>
        <a
          href="https://www.linkedin.com/in/resilient-technologies-restec-52a222322/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn className="text-white hover:text-[#ED4251]" size={30} />
        </a>
        <a
          href="https://www.youtube.com/@ResTec-IA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube className="text-white hover:text-[#ED4251]" size={30} />
        </a>
      </div>
      <p className="text-text-dark">
        &copy; 2025 Resilient Technologies. Todos los derechos reservados.
      </p>
    </footer>
  );
};

export default Footer;
